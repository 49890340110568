<template>
  <div id="structuredraw">
    <MyheadCompnent></MyheadCompnent>
    <StructureDrawComponent></StructureDrawComponent>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
  import StructureDrawComponent from '@/components/Browse/StructureDrawComponent.vue'
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  export default {
    name: 'StructureDraw',
    data() {
      return {

      }
    },
    components: {
      StructureDrawComponent,
      MyheadCompnent,
      MyfooterCompnent,
    },
  }
</script>

<style scoped lang="scss">
</style>